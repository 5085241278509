/**
 * Prepares Z-index values for layers depending on the order they should be displayed
 * https://deck.gl/docs/api-reference/core/layer#getpolygonoffset
 * @param layers  Layers ordered in the way they should be displayed. First layer will be the one displayed on top (most visible) and last layer will be the one displayed at the bottom (less visible) Must include any layer that can potentially be displayed
 */
const layerZIndex = new Map();
export function setupZIndexValuesForLayers(layersIds) {
    const numberOfLayers = layersIds.length;
    if (numberOfLayers === 0) {
        throw new Error('No layers provided when trying to define Z-index values, at least a layer must be provided');
    }
    const n = layersIds.length;
    layersIds.forEach((layerId, index) => {
        let zIndex;
        if (index < n) {
            zIndex = -100 * (n - index);
        }
        else {
            zIndex = 100 * (index - n + 1);
        }
        layerZIndex.set(layerId, zIndex);
    });
}
export function getZIndexValueForLayer(layer) {
    const normalizedLayerName = layer.split('-')[0];
    const layerZIndexValue = layerZIndex.get(normalizedLayerName);
    if (layerZIndexValue === undefined) {
        throw new Error(`Layer ${layer} does not have a Z-index value defined`);
    }
    return [0, layerZIndexValue];
}
